import React from "react";

import * as styles from "./Platform.module.scss";

interface PlatformData {
  title: string;
  desc: string;
  media: string;
}

interface PlatformProps {
  platforms: Array<PlatformData>;
}

const Platform: React.FC<PlatformProps> = ({ platforms }) => {
  return (
    <section className={styles.Platform}>
      <div className={styles.Platform_container}>
        <header id="header" className={styles.Platform_header}>
          <h3 className={styles.Platform_header_title}>
            The new platform for launching and growing new enterprises.
          </h3>
        </header>
        <div className={styles.Platform_content}>
          {platforms.map((platform, index) => (
            <div key={platform.title} className={styles.Platform_item}>
              <div className={styles.Platform_itemImg}>
                <img src={platform.media} alt={`Launchpad ${platform.title}`} />
              </div>
              <h4 className={styles.Platform_itemTitle}>{platform.title}</h4>
              <p className={styles.Platform_itemDescription}>{platform.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Platform;
