// extracted by mini-css-extract-plugin
export var Pricing = "Pricing-module--Pricing--ivWdR";
export var Pricing_container = "Pricing-module--Pricing_container--Tis8e";
export var Pricing_content = "Pricing-module--Pricing_content--xhmod";
export var Pricing_header = "Pricing-module--Pricing_header--sDnqz";
export var Pricing_img = "Pricing-module--Pricing_img--FssDm";
export var Pricing_loader = "Pricing-module--Pricing_loader--+h1oH";
export var Pricing_priceItem = "Pricing-module--Pricing_priceItem--BvqNr";
export var Pricing_priceList = "Pricing-module--Pricing_priceList--i6zQL";
export var Pricing_title = "Pricing-module--Pricing_title--k0vyw";
export var container = "Pricing-module--container--dj4hD";
export var gatsbyImageWrapperConstrained = "Pricing-module--gatsby-image-wrapper-constrained--g4Cc8";
export var gridSpan1010 = "Pricing-module--grid-span-10-10--5BTl3";
export var gridSpan1011 = "Pricing-module--grid-span-10-11--LhAeW";
export var gridSpan102 = "Pricing-module--grid-span-10-2--D6-Vj";
export var gridSpan103 = "Pricing-module--grid-span-10-3--On1Y4";
export var gridSpan104 = "Pricing-module--grid-span-10-4--VSqgx";
export var gridSpan105 = "Pricing-module--grid-span-10-5--Mq5dg";
export var gridSpan106 = "Pricing-module--grid-span-10-6--bhrcX";
export var gridSpan107 = "Pricing-module--grid-span-10-7--CBcII";
export var gridSpan108 = "Pricing-module--grid-span-10-8--Q9fyD";
export var gridSpan109 = "Pricing-module--grid-span-10-9--oIJmS";
export var gridSpan110 = "Pricing-module--grid-span-1-10--PHHhU";
export var gridSpan111 = "Pricing-module--grid-span-1-11--BDkYw";
export var gridSpan1110 = "Pricing-module--grid-span-11-10--14vzw";
export var gridSpan1111 = "Pricing-module--grid-span-11-11--HmC5M";
export var gridSpan112 = "Pricing-module--grid-span-11-2--d83y3";
export var gridSpan113 = "Pricing-module--grid-span-11-3--3sblT";
export var gridSpan114 = "Pricing-module--grid-span-11-4--usLwz";
export var gridSpan115 = "Pricing-module--grid-span-11-5--gLm1T";
export var gridSpan116 = "Pricing-module--grid-span-11-6--O837O";
export var gridSpan117 = "Pricing-module--grid-span-11-7--6mjN2";
export var gridSpan118 = "Pricing-module--grid-span-11-8--8efbC";
export var gridSpan119 = "Pricing-module--grid-span-11-9--YhZeL";
export var gridSpan12 = "Pricing-module--grid-span-1-2--VVy4k";
export var gridSpan1210 = "Pricing-module--grid-span-12-10--ucY6P";
export var gridSpan1211 = "Pricing-module--grid-span-12-11--xMtTm";
export var gridSpan122 = "Pricing-module--grid-span-12-2--z4hy0";
export var gridSpan123 = "Pricing-module--grid-span-12-3--OZ-pE";
export var gridSpan124 = "Pricing-module--grid-span-12-4--TaMDB";
export var gridSpan125 = "Pricing-module--grid-span-12-5--2G0co";
export var gridSpan126 = "Pricing-module--grid-span-12-6--nAOrd";
export var gridSpan127 = "Pricing-module--grid-span-12-7--Ds2eJ";
export var gridSpan128 = "Pricing-module--grid-span-12-8--XmGjS";
export var gridSpan129 = "Pricing-module--grid-span-12-9--1OHTD";
export var gridSpan13 = "Pricing-module--grid-span-1-3--1p98l";
export var gridSpan14 = "Pricing-module--grid-span-1-4--zIBJW";
export var gridSpan15 = "Pricing-module--grid-span-1-5--soUbb";
export var gridSpan16 = "Pricing-module--grid-span-1-6--ipzX2";
export var gridSpan17 = "Pricing-module--grid-span-1-7--gGBe7";
export var gridSpan18 = "Pricing-module--grid-span-1-8--DyGUu";
export var gridSpan19 = "Pricing-module--grid-span-1-9--UHmp3";
export var gridSpan210 = "Pricing-module--grid-span-2-10--JzhX6";
export var gridSpan211 = "Pricing-module--grid-span-2-11--2t1tQ";
export var gridSpan22 = "Pricing-module--grid-span-2-2--fGeqC";
export var gridSpan23 = "Pricing-module--grid-span-2-3--bSU21";
export var gridSpan24 = "Pricing-module--grid-span-2-4--NIzq-";
export var gridSpan25 = "Pricing-module--grid-span-2-5--piW88";
export var gridSpan26 = "Pricing-module--grid-span-2-6--viWFE";
export var gridSpan27 = "Pricing-module--grid-span-2-7--NBBx8";
export var gridSpan28 = "Pricing-module--grid-span-2-8--wpscI";
export var gridSpan29 = "Pricing-module--grid-span-2-9--f+jzL";
export var gridSpan310 = "Pricing-module--grid-span-3-10--G1th9";
export var gridSpan311 = "Pricing-module--grid-span-3-11--9sW8h";
export var gridSpan32 = "Pricing-module--grid-span-3-2--OuNEB";
export var gridSpan33 = "Pricing-module--grid-span-3-3--tsjzX";
export var gridSpan34 = "Pricing-module--grid-span-3-4--FIqJq";
export var gridSpan35 = "Pricing-module--grid-span-3-5--FinkP";
export var gridSpan36 = "Pricing-module--grid-span-3-6--dUSvz";
export var gridSpan37 = "Pricing-module--grid-span-3-7--2Ow9y";
export var gridSpan38 = "Pricing-module--grid-span-3-8--QKrNP";
export var gridSpan39 = "Pricing-module--grid-span-3-9--1Jjeh";
export var gridSpan410 = "Pricing-module--grid-span-4-10--kJ1RP";
export var gridSpan411 = "Pricing-module--grid-span-4-11--mod5Y";
export var gridSpan42 = "Pricing-module--grid-span-4-2---Mi+4";
export var gridSpan43 = "Pricing-module--grid-span-4-3--IxqCZ";
export var gridSpan44 = "Pricing-module--grid-span-4-4--6ij5n";
export var gridSpan45 = "Pricing-module--grid-span-4-5--+zb-s";
export var gridSpan46 = "Pricing-module--grid-span-4-6--CET82";
export var gridSpan47 = "Pricing-module--grid-span-4-7--52bTV";
export var gridSpan48 = "Pricing-module--grid-span-4-8--H7WpR";
export var gridSpan49 = "Pricing-module--grid-span-4-9--HVqIh";
export var gridSpan510 = "Pricing-module--grid-span-5-10--XEvB5";
export var gridSpan511 = "Pricing-module--grid-span-5-11--wFURM";
export var gridSpan52 = "Pricing-module--grid-span-5-2--ieCKM";
export var gridSpan53 = "Pricing-module--grid-span-5-3--xtLJr";
export var gridSpan54 = "Pricing-module--grid-span-5-4--UsxKx";
export var gridSpan55 = "Pricing-module--grid-span-5-5--ORwqu";
export var gridSpan56 = "Pricing-module--grid-span-5-6--A63+V";
export var gridSpan57 = "Pricing-module--grid-span-5-7--2fE4V";
export var gridSpan58 = "Pricing-module--grid-span-5-8--9kjFx";
export var gridSpan59 = "Pricing-module--grid-span-5-9--5LBbT";
export var gridSpan610 = "Pricing-module--grid-span-6-10--MmjuX";
export var gridSpan611 = "Pricing-module--grid-span-6-11--reV+A";
export var gridSpan62 = "Pricing-module--grid-span-6-2--ZcNmX";
export var gridSpan63 = "Pricing-module--grid-span-6-3--XUBpd";
export var gridSpan64 = "Pricing-module--grid-span-6-4--di-OJ";
export var gridSpan65 = "Pricing-module--grid-span-6-5--g-Aij";
export var gridSpan66 = "Pricing-module--grid-span-6-6--ZFrDN";
export var gridSpan67 = "Pricing-module--grid-span-6-7--bYrNw";
export var gridSpan68 = "Pricing-module--grid-span-6-8--rDFaj";
export var gridSpan69 = "Pricing-module--grid-span-6-9--OZ1Mv";
export var gridSpan710 = "Pricing-module--grid-span-7-10--cIqJi";
export var gridSpan711 = "Pricing-module--grid-span-7-11--ycbZk";
export var gridSpan72 = "Pricing-module--grid-span-7-2--2g546";
export var gridSpan73 = "Pricing-module--grid-span-7-3--uRlOc";
export var gridSpan74 = "Pricing-module--grid-span-7-4--DUAVi";
export var gridSpan75 = "Pricing-module--grid-span-7-5--ds9i9";
export var gridSpan76 = "Pricing-module--grid-span-7-6--kewyG";
export var gridSpan77 = "Pricing-module--grid-span-7-7--LWn7E";
export var gridSpan78 = "Pricing-module--grid-span-7-8--eKkLv";
export var gridSpan79 = "Pricing-module--grid-span-7-9--8DiNK";
export var gridSpan810 = "Pricing-module--grid-span-8-10--FvjOd";
export var gridSpan811 = "Pricing-module--grid-span-8-11--C6dpK";
export var gridSpan82 = "Pricing-module--grid-span-8-2--3qXQ7";
export var gridSpan83 = "Pricing-module--grid-span-8-3--jRVQD";
export var gridSpan84 = "Pricing-module--grid-span-8-4--GsvMJ";
export var gridSpan85 = "Pricing-module--grid-span-8-5---S+rs";
export var gridSpan86 = "Pricing-module--grid-span-8-6--TgurI";
export var gridSpan87 = "Pricing-module--grid-span-8-7--2JK1l";
export var gridSpan88 = "Pricing-module--grid-span-8-8--yjtq7";
export var gridSpan89 = "Pricing-module--grid-span-8-9--Enu4q";
export var gridSpan910 = "Pricing-module--grid-span-9-10--BBWtz";
export var gridSpan911 = "Pricing-module--grid-span-9-11--8yowG";
export var gridSpan92 = "Pricing-module--grid-span-9-2--T1Tb+";
export var gridSpan93 = "Pricing-module--grid-span-9-3--DswuK";
export var gridSpan94 = "Pricing-module--grid-span-9-4--6gNvx";
export var gridSpan95 = "Pricing-module--grid-span-9-5--PJwXY";
export var gridSpan96 = "Pricing-module--grid-span-9-6--jbePb";
export var gridSpan97 = "Pricing-module--grid-span-9-7--OrNdD";
export var gridSpan98 = "Pricing-module--grid-span-9-8--lw196";
export var gridSpan99 = "Pricing-module--grid-span-9-9--7NiZI";
export var textBreak = "Pricing-module--text-break--Bq8tH";