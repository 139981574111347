import { Button, ButtonVariant } from "brass-ui-kit";
import { Link } from "@reach/router";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./Ebook.module.scss";
import { ebookPageUrl } from "data/pageUrl";

const Ebook: React.FC = () => {
  return (
    <section className={styles.Ebook}>
      <div className={styles.Ebook_container}>
        <StaticImage
          src="../../../assets/media/images/launchpad/sme-guide-handbook.png"
          alt="sme handbook"
          loading="eager"
        />
        <div className={styles.Ebook_container_content}>
          <h3 className={styles.Ebook_container_content_title}>
            Download our free business guide e-book
          </h3>
          <h5 className={styles.Ebook_container_content_desc}>
            We have published a free e-book titled ‘The SME Guide’ to provide
            small and medium business owners with resources that can help in
            knowing how to start, run, grow, and safe-keep their business
            effectively in Nigeria.
          </h5>
          <Button
            variant={ButtonVariant.Primary}
            className={styles.Ebook_container_content_btn}
            as={Link}
            to={ebookPageUrl}
          >
            Download our business guide e-book
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Ebook;
