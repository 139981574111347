// extracted by mini-css-extract-plugin
export var Partners = "Partners-module--Partners--HXJ1C";
export var Partners_container = "Partners-module--Partners_container--7E8RA";
export var Partners_list = "Partners-module--Partners_list--gNsuu";
export var Partners_partner = "Partners-module--Partners_partner--gjsCs";
export var Partners_partnerDesc = "Partners-module--Partners_partnerDesc--d7aDX";
export var Partners_partnerDiscount = "Partners-module--Partners_partnerDiscount--OMQXV";
export var Partners_partnerImg = "Partners-module--Partners_partnerImg--KyvrP";
export var Partners_swiper = "Partners-module--Partners_swiper--2N3B6";
export var Partners_text = "Partners-module--Partners_text--Qkl0Q";
export var container = "Partners-module--container--3k96E";
export var gatsbyImageWrapperConstrained = "Partners-module--gatsby-image-wrapper-constrained--yjnkg";
export var gridSpan1010 = "Partners-module--grid-span-10-10--ljAXd";
export var gridSpan1011 = "Partners-module--grid-span-10-11--udZHw";
export var gridSpan102 = "Partners-module--grid-span-10-2--UK5Kd";
export var gridSpan103 = "Partners-module--grid-span-10-3---hnxV";
export var gridSpan104 = "Partners-module--grid-span-10-4--Ax3z1";
export var gridSpan105 = "Partners-module--grid-span-10-5--tCa7T";
export var gridSpan106 = "Partners-module--grid-span-10-6--Ugxii";
export var gridSpan107 = "Partners-module--grid-span-10-7--lnWv0";
export var gridSpan108 = "Partners-module--grid-span-10-8--ruFWX";
export var gridSpan109 = "Partners-module--grid-span-10-9--1nk0b";
export var gridSpan110 = "Partners-module--grid-span-1-10--1RavG";
export var gridSpan111 = "Partners-module--grid-span-1-11--AFhpx";
export var gridSpan1110 = "Partners-module--grid-span-11-10--j1g9z";
export var gridSpan1111 = "Partners-module--grid-span-11-11--0VHtl";
export var gridSpan112 = "Partners-module--grid-span-11-2--PM2rv";
export var gridSpan113 = "Partners-module--grid-span-11-3--Evy0c";
export var gridSpan114 = "Partners-module--grid-span-11-4--dixwA";
export var gridSpan115 = "Partners-module--grid-span-11-5--J0+NN";
export var gridSpan116 = "Partners-module--grid-span-11-6--SltqC";
export var gridSpan117 = "Partners-module--grid-span-11-7--Ov2IQ";
export var gridSpan118 = "Partners-module--grid-span-11-8--4gbFb";
export var gridSpan119 = "Partners-module--grid-span-11-9--Y9MRy";
export var gridSpan12 = "Partners-module--grid-span-1-2--ajmNY";
export var gridSpan1210 = "Partners-module--grid-span-12-10--dcPZn";
export var gridSpan1211 = "Partners-module--grid-span-12-11--KgtUV";
export var gridSpan122 = "Partners-module--grid-span-12-2--e4jZb";
export var gridSpan123 = "Partners-module--grid-span-12-3--bUV9g";
export var gridSpan124 = "Partners-module--grid-span-12-4--GDbIE";
export var gridSpan125 = "Partners-module--grid-span-12-5--sf0Nv";
export var gridSpan126 = "Partners-module--grid-span-12-6--003f0";
export var gridSpan127 = "Partners-module--grid-span-12-7--AHuA+";
export var gridSpan128 = "Partners-module--grid-span-12-8--41D9H";
export var gridSpan129 = "Partners-module--grid-span-12-9--wGSvx";
export var gridSpan13 = "Partners-module--grid-span-1-3--9WVtQ";
export var gridSpan14 = "Partners-module--grid-span-1-4--oXLT+";
export var gridSpan15 = "Partners-module--grid-span-1-5--aNnEy";
export var gridSpan16 = "Partners-module--grid-span-1-6--Poevp";
export var gridSpan17 = "Partners-module--grid-span-1-7--BkaTL";
export var gridSpan18 = "Partners-module--grid-span-1-8--zfWgG";
export var gridSpan19 = "Partners-module--grid-span-1-9--yqDLk";
export var gridSpan210 = "Partners-module--grid-span-2-10--n5-cw";
export var gridSpan211 = "Partners-module--grid-span-2-11--wI99z";
export var gridSpan22 = "Partners-module--grid-span-2-2--fbuVi";
export var gridSpan23 = "Partners-module--grid-span-2-3--y3t8L";
export var gridSpan24 = "Partners-module--grid-span-2-4--Mrfeu";
export var gridSpan25 = "Partners-module--grid-span-2-5--ktK2e";
export var gridSpan26 = "Partners-module--grid-span-2-6--NQU8x";
export var gridSpan27 = "Partners-module--grid-span-2-7--y-CZ7";
export var gridSpan28 = "Partners-module--grid-span-2-8--u+52w";
export var gridSpan29 = "Partners-module--grid-span-2-9--IerCj";
export var gridSpan310 = "Partners-module--grid-span-3-10--KU2lf";
export var gridSpan311 = "Partners-module--grid-span-3-11--loiWa";
export var gridSpan32 = "Partners-module--grid-span-3-2--PAnkG";
export var gridSpan33 = "Partners-module--grid-span-3-3--lkYAp";
export var gridSpan34 = "Partners-module--grid-span-3-4--2Iyv6";
export var gridSpan35 = "Partners-module--grid-span-3-5--s7bdc";
export var gridSpan36 = "Partners-module--grid-span-3-6--GQ1rN";
export var gridSpan37 = "Partners-module--grid-span-3-7--S0-5b";
export var gridSpan38 = "Partners-module--grid-span-3-8--lD5QO";
export var gridSpan39 = "Partners-module--grid-span-3-9--NzRBN";
export var gridSpan410 = "Partners-module--grid-span-4-10--LZPud";
export var gridSpan411 = "Partners-module--grid-span-4-11--3bHyh";
export var gridSpan42 = "Partners-module--grid-span-4-2--LU-XS";
export var gridSpan43 = "Partners-module--grid-span-4-3--qgaZE";
export var gridSpan44 = "Partners-module--grid-span-4-4--cNXWR";
export var gridSpan45 = "Partners-module--grid-span-4-5--HZ-Qv";
export var gridSpan46 = "Partners-module--grid-span-4-6--vtTzi";
export var gridSpan47 = "Partners-module--grid-span-4-7--iYx5p";
export var gridSpan48 = "Partners-module--grid-span-4-8--SqIif";
export var gridSpan49 = "Partners-module--grid-span-4-9--bQEwN";
export var gridSpan510 = "Partners-module--grid-span-5-10--NoP5S";
export var gridSpan511 = "Partners-module--grid-span-5-11--U6qkZ";
export var gridSpan52 = "Partners-module--grid-span-5-2--HkODj";
export var gridSpan53 = "Partners-module--grid-span-5-3--veIpQ";
export var gridSpan54 = "Partners-module--grid-span-5-4--SvPvM";
export var gridSpan55 = "Partners-module--grid-span-5-5--Km4sF";
export var gridSpan56 = "Partners-module--grid-span-5-6--dWiEd";
export var gridSpan57 = "Partners-module--grid-span-5-7--A7wt9";
export var gridSpan58 = "Partners-module--grid-span-5-8--4OwIK";
export var gridSpan59 = "Partners-module--grid-span-5-9--4nZ3F";
export var gridSpan610 = "Partners-module--grid-span-6-10--jyOhV";
export var gridSpan611 = "Partners-module--grid-span-6-11--STner";
export var gridSpan62 = "Partners-module--grid-span-6-2--yHGn5";
export var gridSpan63 = "Partners-module--grid-span-6-3--NJ-85";
export var gridSpan64 = "Partners-module--grid-span-6-4--mqHWK";
export var gridSpan65 = "Partners-module--grid-span-6-5--dahIK";
export var gridSpan66 = "Partners-module--grid-span-6-6--NAj8J";
export var gridSpan67 = "Partners-module--grid-span-6-7--fS7zC";
export var gridSpan68 = "Partners-module--grid-span-6-8--FaL9m";
export var gridSpan69 = "Partners-module--grid-span-6-9--nGPHr";
export var gridSpan710 = "Partners-module--grid-span-7-10--dbtOe";
export var gridSpan711 = "Partners-module--grid-span-7-11--AFi5G";
export var gridSpan72 = "Partners-module--grid-span-7-2--CuE-h";
export var gridSpan73 = "Partners-module--grid-span-7-3--xZ329";
export var gridSpan74 = "Partners-module--grid-span-7-4--FhRnw";
export var gridSpan75 = "Partners-module--grid-span-7-5--Mp+G0";
export var gridSpan76 = "Partners-module--grid-span-7-6--RDzi0";
export var gridSpan77 = "Partners-module--grid-span-7-7--BaQ2F";
export var gridSpan78 = "Partners-module--grid-span-7-8--J6s8H";
export var gridSpan79 = "Partners-module--grid-span-7-9--6KHO8";
export var gridSpan810 = "Partners-module--grid-span-8-10--zjqwt";
export var gridSpan811 = "Partners-module--grid-span-8-11--abkwj";
export var gridSpan82 = "Partners-module--grid-span-8-2--5UGwu";
export var gridSpan83 = "Partners-module--grid-span-8-3--+8XDt";
export var gridSpan84 = "Partners-module--grid-span-8-4--ao1g8";
export var gridSpan85 = "Partners-module--grid-span-8-5--tehah";
export var gridSpan86 = "Partners-module--grid-span-8-6--HLK-u";
export var gridSpan87 = "Partners-module--grid-span-8-7--kyCz8";
export var gridSpan88 = "Partners-module--grid-span-8-8--Xkaat";
export var gridSpan89 = "Partners-module--grid-span-8-9--4NNSd";
export var gridSpan910 = "Partners-module--grid-span-9-10--RKlMS";
export var gridSpan911 = "Partners-module--grid-span-9-11--9gstt";
export var gridSpan92 = "Partners-module--grid-span-9-2--EoolQ";
export var gridSpan93 = "Partners-module--grid-span-9-3--wzQ32";
export var gridSpan94 = "Partners-module--grid-span-9-4--lAl2P";
export var gridSpan95 = "Partners-module--grid-span-9-5--OOcaL";
export var gridSpan96 = "Partners-module--grid-span-9-6--IyGXv";
export var gridSpan97 = "Partners-module--grid-span-9-7--TKE9b";
export var gridSpan98 = "Partners-module--grid-span-9-8---QBRq";
export var gridSpan99 = "Partners-module--grid-span-9-9--fGy8f";
export var textBreak = "Partners-module--text-break--bD-hF";