import React from "react";

import * as styles from "./Testimonials.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Play } from "assets/media/svgs";
import { CustomVideoPlayer } from "components/design-system";

const Testimonials: React.FC = () => {
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = React.useState(false);

  return (
    <div className={styles.Testimonials}>
      <div className={styles.Testimonials_container}>
        <div className={styles.Testimonials_header}>
          <h2 className={styles.Testimonials_title}>
            Don’t just take our word for it
          </h2>
          <p className={styles.Testimonials_subTitle}>
            Here is what Rosemary of RMDS retail has to say about us
          </p>
        </div>
        <div className={styles.Testimonials_content}>
          <div className={styles.Testimonials_video}>
            <StaticImage
              alt="testimonial video"
              src="./../../../assets/media/images/launchpad/customer.png"
            />
            <button
              onClick={() => {
                setIsVideoPlayerOpen(true);
              }}
              className={styles.Testimonials_videoButton}
            >
              <Play className={styles.Testimonials_videoButtonIcon} />
              <div className={styles.Testimonials_videoButtonContent}>
                <h6>Play this video</h6>
                <p>To see what she said about us</p>
              </div>
            </button>
          </div>
        </div>
      </div>
      {isVideoPlayerOpen && (
        <CustomVideoPlayer
          handleClose={() => {
            setIsVideoPlayerOpen(false);
          }}
          source="youtube"
          videoID="6C2nZ9j0Jig"
          show={isVideoPlayerOpen}
        />
      )}
    </div>
  );
};

export default Testimonials;
