import React from "react";
import * as styles from "./BusinessIdea.module.scss";
import BusinessIdeaImg from "../../../assets/media/gifs/launchpad/business-idea.gif";

const BusinessIdea: React.FC = () => {
  return (
    <section className={styles.BusinessIdea}>
      <header className={styles.BusinessIdea_header}>
        <h1 className={styles.BusinessIdea_header_title}>
          Turn your idea to a business in days
        </h1>
        <p className={styles.BusinessIdea_header_desc}>
          With the right tools, support, resources and community, get all you
          need to build your business with the best practices and set it for
          growth.
        </p>
      </header>
      <div className={styles.BusinessIdea_img}>
        <img src={BusinessIdeaImg} alt="business idea" />
      </div>
    </section>
  );
};

export default BusinessIdea;
