import React from "react";

import * as styles from "./PartnerWithUs.module.scss";
import { Button, ButtonVariant } from "brass-ui-kit";
import { sendEmail } from "helpers/utils/generics";

const PartnerWithUs: React.FC = () => {
  return (
    <section className={styles.PartnerWithUs}>
      <div className={styles.PartnerWithUs_container}>
        <div className={styles.PartnerWithUs_header}>
          <h2 className={styles.PartnerWithUs_title}>Partner with us</h2>
          <p className={styles.PartnerWithUs_subTitle}>
            Take your company to new heights by partnering with Brass Launchpad.
            Together, we can achieve remarkable success.
          </p>
        </div>
        <Button
          onClick={() =>
            sendEmail({
              email: "launchpad@trybrass.com",
              subject: "Partner with us",
              body: "Hi, I would like to partner with you.",
            })
          }
          variant={ButtonVariant.Primary}
          className={styles.PartnerWithUs_button}
        >
          Reach out to us
        </Button>
      </div>
    </section>
  );
};

export default PartnerWithUs;
