import React from "react";
import * as styles from "./Community.module.scss";
import { Button, ButtonVariant } from "brass-ui-kit";
import FeatureImgThree from "assets/media/images/launchpad/feature-img-3.png";
import FeatureImgTwo from "assets/media/images/launchpad/feature-img-2.png";
import FeatureImgOne from "assets/media/images/launchpad/feature-img-1.png";
import { BBC_WEBSITE_URL, LUNCHPAD_APP_SIGN_UP_URL } from "data/config";

const Community: React.FC = () => {
  return (
    <section className={styles.Community}>
      <div className={styles.Community_container}>
        <div className={styles.Community_container_content}>
          <div className={styles.Community_container_content_media}>
            <div className={styles.Community_container_content_media_left}>
              <img src={FeatureImgOne} alt="" />
              <img src={FeatureImgThree} alt="" />
            </div>
            <div className={styles.Community_container_content_media_right}>
              <img src={FeatureImgTwo} alt="" />
            </div>
          </div>
          <div className={styles.Community_container_content_details}>
            <h3 className={styles.Community_container_content_details_title}>
              Accountability group for ambitious businesses
            </h3>
            <h5 className={styles.Community_container_content_details_desc}>
              Connect with like-minded entrepreneurs for the fast growth of your
              business. Participate in conversations, get tips on best practices
              on a range of essential topics and bond with people on same
              journey.
            </h5>
            <Button
              variant={ButtonVariant.Black}
              className={styles.Community_container_content_details_btn}
              onClick={() => window.open(BBC_WEBSITE_URL, "_blank")}
            >
              Join the community
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
