import React, { useMemo } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { uniqueId } from "lodash";
import { useMediaQuery } from "react-responsive";

import "swiper/scss";
import "swiper/scss/autoplay";
import * as styles from "./Partners.module.scss";

interface Partner {
  id: string;
  attributes: any;
}

interface PartnersProps {
  partners: Array<Partner>;
}

const Partners: React.FC<PartnersProps> = ({ partners }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1600px)" });

  const partnersList = useMemo(() => {
    if (isTabletOrMobile || isBigScreen) {
      return partners;
    }
    return [...partners, ...partners];
  }, [partners, isTabletOrMobile]);

  return (
    <section className={styles.Partners}>
      <div className={styles.Partners_container}>
        <h4 className={styles.Partners_text}>
          We have partnered with some of the best companies to see you move from
          start to success.
        </h4>
        <Swiper
          loop={!isBigScreen}
          autoplay={{
            disableOnInteraction: false,
          }}
          spaceBetween={16}
          pagination={false}
          navigation={false}
          modules={[Autoplay]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 4,
            },
            1600: {
              slidesPerView: 5,
            },
          }}
          className={styles.Partners_swiper}
        >
          {partnersList.map((partner) => (
            <SwiperSlide
              key={uniqueId(`partner`)}
              className={styles.Partners_partner}
            >
              <div className={styles.Partners_partnerImg}>
                <img
                  src={partner.attributes.companyLogo.data?.attributes.url}
                  alt={partner.attributes.companyName}
                />
              </div>
              <p className={styles.Partners_partnerDesc}>
                {partner.attributes.excerpt}
              </p>
              <div className={styles.Partners_partnerDiscount}>
                {partner?.attributes.rewardValue.split(",")[0]}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
