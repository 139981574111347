import axios from "axios";
import * as Sentry from "@sentry/gatsby";

import { LAUNCHPAD_API_URL, LAUNCHPAD_API_KEY } from "data/config";

const LaunchpadService = axios.create({
  baseURL: LAUNCHPAD_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

LaunchpadService.interceptors.request.use((req) => {
  req.headers!["Authorization"] = `Bearer ${LAUNCHPAD_API_KEY}`;
  return req;
});

LaunchpadService.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    Sentry.captureException(err);
    return Promise.reject(err);
  }
);

export default LaunchpadService;
