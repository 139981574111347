import { Button, ButtonVariant } from "brass-ui-kit";
import { BBC_WEBSITE_URL, LUNCHPAD_APP_SIGN_UP_URL } from "data/config";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import * as styles from "./Hero.module.scss";

const Hero: React.FC = () => {
  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <div className={styles.Hero_content}>
          <h5 className={styles.Hero_content_header}>BRASS LAUNCHPAD</h5>
          <h1 className={styles.Hero_content_title}>
            Everything you need to get your business started today
          </h1>
          <h4 className={styles.Hero_content_desc}>
            Move from an idea to getting your business started with the tools,
            resources, community and partnerships you need to succeed.
          </h4>
          <div className={styles.Hero_content_btns}>
            <Button
              variant={ButtonVariant.Primary}
              onClick={() => window.open(LUNCHPAD_APP_SIGN_UP_URL, "_blank")}
              className={styles.Hero_content_btns_btn}
            >
              Start your company
            </Button>
            <Button
              variant={ButtonVariant.Black}
              className={styles.Hero_content_btns_btn}
              onClick={() => window.open(BBC_WEBSITE_URL, "_blank")}
            >
              Join the community
            </Button>
          </div>
        </div>
        <div className={styles.Hero_img}>
          <StaticImage
            objectFit="contain"
            src="../../../assets/media/images/launchpad/hero-bottom.png"
            alt="hero"
            loading="eager"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
