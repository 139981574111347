import React from "react";

import * as styles from "./WhyLaunchpad.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { IconCheckGreen } from "assets/media/svgs";
import { Button, ButtonVariant } from "brass-ui-kit";

const WhyLaunchpad: React.FC = () => {
  return (
    <section className={styles.WhyLaunchpad}>
      <div className={styles.WhyLaunchpad_container}>
        <div className={styles.WhyLaunchpad_content}>
          <h2 className={styles.WhyLaunchpad_title}>Why Launchpad?</h2>
          <ul className={styles.WhyLaunchpad_list}>
            <li className={styles.WhyLaunchpad_listItem}>
              <IconCheckGreen />
              <span>Get your CAC documents within 7-10 working days</span>
            </li>
            <li className={styles.WhyLaunchpad_listItem}>
              <IconCheckGreen />
              <span>
                Save up to ₦100,000 worth of discount from our partners
              </span>
            </li>
            <li className={styles.WhyLaunchpad_listItem}>
              <IconCheckGreen />
              <span>Access to free Business Templates and Resources</span>
            </li>
            <li className={styles.WhyLaunchpad_listItem}>
              <IconCheckGreen />
              <span>No Legals search fees</span>
            </li>
            <li className={styles.WhyLaunchpad_listItem}>
              <IconCheckGreen />
              <span>
                Enjoy 3 month free access to our suite of helpful financial
                tools like Brass Payroll, Brass Invoice and Brass Terminals
              </span>
            </li>
          </ul>
          <Button
            variant={ButtonVariant.Primary}
            className={styles.WhyLaunchpad_button}
          >
            Start your company
          </Button>
        </div>
        <div className={styles.WhyLaunchpad_img}>
          <StaticImage
            alt="Why Launchpad"
            src="./../../../assets/media/images/launchpad/hero.png"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyLaunchpad;
