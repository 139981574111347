import React from "react";

import LaunchpadService from "services/launchpadService";

import * as styles from "./Pricing.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { IconCheckGreen } from "assets/media/svgs";
import { Spinner } from "components/design-system";
import { getLaunchpadCompanyTypes } from "functions";
import { CompanyType } from "types";
import { koboToNaira } from "helpers/utils/generics";

interface PricingState {
  companyTypes: CompanyType[];
  isFetchingCompanyTypes: boolean;
}

const Pricing: React.FC = () => {
  const [state, setState] = React.useState<PricingState>({
    companyTypes: [],
    isFetchingCompanyTypes: true,
  });

  const handleStateUpdate = (newState: Partial<PricingState>) =>
    setState({ ...state, ...newState });

  const getCompanyTypes = async () => {
    try {
      const response = await getLaunchpadCompanyTypes();
      handleStateUpdate({
        companyTypes: response.data.data.sort(
          (a, b) => +a.price.raw - +b.price.raw
        ),
        isFetchingCompanyTypes: false,
      });
    } catch (err) {
      handleStateUpdate({
        isFetchingCompanyTypes: false,
      });
    }
  };

  React.useEffect(() => {
    getCompanyTypes();
  }, []);

  return (
    <section className={styles.Pricing}>
      {state.isFetchingCompanyTypes ? (
        <Spinner className={styles.Pricing_loader} />
      ) : (
        <div className={styles.Pricing_container}>
          <div className={styles.Pricing_header}>
            <h3 className={styles.Pricing_title}>
              Start your company without breaking the bank
            </h3>
          </div>
          <div className={styles.Pricing_content}>
            <ul className={styles.Pricing_priceList}>
              {state.companyTypes.map((companyType) => (
                <li key={companyType.id} className={styles.Pricing_priceItem}>
                  <div>
                    <IconCheckGreen />
                    <span>
                      {companyType.display_name}{" "}
                      {companyType.requires_foreign_shareholder
                        ? "(Non-Nigerian Shareholders)"
                        : null}
                    </span>
                  </div>
                  <span>
                    ₦
                    {koboToNaira(+companyType.price.raw, {
                      minimumFractionDigits: 0,
                    })}
                  </span>
                </li>
              ))}
            </ul>
            <div className={styles.Pricing_img}>
              <StaticImage
                alt="hero"
                loading="eager"
                objectFit="contain"
                src="../../../assets/media/images/launchpad/pricing-img.jpeg"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Pricing;
