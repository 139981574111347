// extracted by mini-css-extract-plugin
export var Testimonials = "Testimonials-module--Testimonials--8vpfz";
export var Testimonials_container = "Testimonials-module--Testimonials_container--uQakS";
export var Testimonials_content = "Testimonials-module--Testimonials_content--KfL+j";
export var Testimonials_header = "Testimonials-module--Testimonials_header--XelPf";
export var Testimonials_subTitle = "Testimonials-module--Testimonials_subTitle--Li5TK";
export var Testimonials_title = "Testimonials-module--Testimonials_title--+0bn0";
export var Testimonials_video = "Testimonials-module--Testimonials_video--0nsHh";
export var Testimonials_videoButton = "Testimonials-module--Testimonials_videoButton--adm4d";
export var Testimonials_videoButtonContent = "Testimonials-module--Testimonials_videoButtonContent--eHc39";
export var Testimonials_videoButtonIcon = "Testimonials-module--Testimonials_videoButtonIcon--dSiK5";
export var container = "Testimonials-module--container--CCJzh";
export var gatsbyImageWrapperConstrained = "Testimonials-module--gatsby-image-wrapper-constrained--Y9OaE";
export var gridSpan1010 = "Testimonials-module--grid-span-10-10--ztKLu";
export var gridSpan1011 = "Testimonials-module--grid-span-10-11--fCR+S";
export var gridSpan102 = "Testimonials-module--grid-span-10-2--9Vxp3";
export var gridSpan103 = "Testimonials-module--grid-span-10-3--69PDn";
export var gridSpan104 = "Testimonials-module--grid-span-10-4--uo-ps";
export var gridSpan105 = "Testimonials-module--grid-span-10-5--79qaQ";
export var gridSpan106 = "Testimonials-module--grid-span-10-6--K52au";
export var gridSpan107 = "Testimonials-module--grid-span-10-7--Z6s09";
export var gridSpan108 = "Testimonials-module--grid-span-10-8--5-Xfj";
export var gridSpan109 = "Testimonials-module--grid-span-10-9--8mcz9";
export var gridSpan110 = "Testimonials-module--grid-span-1-10--BVRLN";
export var gridSpan111 = "Testimonials-module--grid-span-1-11--4Cmn+";
export var gridSpan1110 = "Testimonials-module--grid-span-11-10--Jx+id";
export var gridSpan1111 = "Testimonials-module--grid-span-11-11--Pomh-";
export var gridSpan112 = "Testimonials-module--grid-span-11-2--CA9gP";
export var gridSpan113 = "Testimonials-module--grid-span-11-3--xhDJs";
export var gridSpan114 = "Testimonials-module--grid-span-11-4--33iTC";
export var gridSpan115 = "Testimonials-module--grid-span-11-5--FbCCd";
export var gridSpan116 = "Testimonials-module--grid-span-11-6--dOKoh";
export var gridSpan117 = "Testimonials-module--grid-span-11-7--x3TNt";
export var gridSpan118 = "Testimonials-module--grid-span-11-8--m4k6D";
export var gridSpan119 = "Testimonials-module--grid-span-11-9--XkjkA";
export var gridSpan12 = "Testimonials-module--grid-span-1-2--PDdbE";
export var gridSpan1210 = "Testimonials-module--grid-span-12-10--4T7uM";
export var gridSpan1211 = "Testimonials-module--grid-span-12-11--3q7Em";
export var gridSpan122 = "Testimonials-module--grid-span-12-2--FLsWO";
export var gridSpan123 = "Testimonials-module--grid-span-12-3--NJ8ba";
export var gridSpan124 = "Testimonials-module--grid-span-12-4--7ga72";
export var gridSpan125 = "Testimonials-module--grid-span-12-5--03Hl+";
export var gridSpan126 = "Testimonials-module--grid-span-12-6--BstvE";
export var gridSpan127 = "Testimonials-module--grid-span-12-7--LBoTO";
export var gridSpan128 = "Testimonials-module--grid-span-12-8--pGQL+";
export var gridSpan129 = "Testimonials-module--grid-span-12-9--md9DO";
export var gridSpan13 = "Testimonials-module--grid-span-1-3--W2yax";
export var gridSpan14 = "Testimonials-module--grid-span-1-4--5dwi3";
export var gridSpan15 = "Testimonials-module--grid-span-1-5--q8Q2v";
export var gridSpan16 = "Testimonials-module--grid-span-1-6--81v63";
export var gridSpan17 = "Testimonials-module--grid-span-1-7--fF5VU";
export var gridSpan18 = "Testimonials-module--grid-span-1-8--C9oXL";
export var gridSpan19 = "Testimonials-module--grid-span-1-9--Xbol8";
export var gridSpan210 = "Testimonials-module--grid-span-2-10--0Mh1V";
export var gridSpan211 = "Testimonials-module--grid-span-2-11--hZeXV";
export var gridSpan22 = "Testimonials-module--grid-span-2-2--5fYdG";
export var gridSpan23 = "Testimonials-module--grid-span-2-3--so+Td";
export var gridSpan24 = "Testimonials-module--grid-span-2-4--QTYr+";
export var gridSpan25 = "Testimonials-module--grid-span-2-5--OjADO";
export var gridSpan26 = "Testimonials-module--grid-span-2-6--TTi+1";
export var gridSpan27 = "Testimonials-module--grid-span-2-7--0qEnA";
export var gridSpan28 = "Testimonials-module--grid-span-2-8--FfZBo";
export var gridSpan29 = "Testimonials-module--grid-span-2-9--7tGuV";
export var gridSpan310 = "Testimonials-module--grid-span-3-10--Vfpmx";
export var gridSpan311 = "Testimonials-module--grid-span-3-11--s+GKi";
export var gridSpan32 = "Testimonials-module--grid-span-3-2--nPtW4";
export var gridSpan33 = "Testimonials-module--grid-span-3-3--61bMA";
export var gridSpan34 = "Testimonials-module--grid-span-3-4--Q4mru";
export var gridSpan35 = "Testimonials-module--grid-span-3-5--KPd9M";
export var gridSpan36 = "Testimonials-module--grid-span-3-6--xS7vi";
export var gridSpan37 = "Testimonials-module--grid-span-3-7--8n0WV";
export var gridSpan38 = "Testimonials-module--grid-span-3-8--BAve1";
export var gridSpan39 = "Testimonials-module--grid-span-3-9--aYynM";
export var gridSpan410 = "Testimonials-module--grid-span-4-10--EWBq0";
export var gridSpan411 = "Testimonials-module--grid-span-4-11--tER83";
export var gridSpan42 = "Testimonials-module--grid-span-4-2--cIIzX";
export var gridSpan43 = "Testimonials-module--grid-span-4-3--9MtRN";
export var gridSpan44 = "Testimonials-module--grid-span-4-4--69QRq";
export var gridSpan45 = "Testimonials-module--grid-span-4-5--1MXHj";
export var gridSpan46 = "Testimonials-module--grid-span-4-6--hQwMy";
export var gridSpan47 = "Testimonials-module--grid-span-4-7--fgnwp";
export var gridSpan48 = "Testimonials-module--grid-span-4-8--R5m+Z";
export var gridSpan49 = "Testimonials-module--grid-span-4-9--GLOzH";
export var gridSpan510 = "Testimonials-module--grid-span-5-10--S6Vc3";
export var gridSpan511 = "Testimonials-module--grid-span-5-11--DDV+M";
export var gridSpan52 = "Testimonials-module--grid-span-5-2--cxtgs";
export var gridSpan53 = "Testimonials-module--grid-span-5-3--5sChw";
export var gridSpan54 = "Testimonials-module--grid-span-5-4--9TIFI";
export var gridSpan55 = "Testimonials-module--grid-span-5-5--iCT22";
export var gridSpan56 = "Testimonials-module--grid-span-5-6--JRQL-";
export var gridSpan57 = "Testimonials-module--grid-span-5-7--OP3WG";
export var gridSpan58 = "Testimonials-module--grid-span-5-8--rwY+-";
export var gridSpan59 = "Testimonials-module--grid-span-5-9--rfzTs";
export var gridSpan610 = "Testimonials-module--grid-span-6-10--d0nYy";
export var gridSpan611 = "Testimonials-module--grid-span-6-11--Z9lnd";
export var gridSpan62 = "Testimonials-module--grid-span-6-2--V-oUH";
export var gridSpan63 = "Testimonials-module--grid-span-6-3--2fKrd";
export var gridSpan64 = "Testimonials-module--grid-span-6-4--LtTZa";
export var gridSpan65 = "Testimonials-module--grid-span-6-5--w4gIp";
export var gridSpan66 = "Testimonials-module--grid-span-6-6--8xBxE";
export var gridSpan67 = "Testimonials-module--grid-span-6-7--T7oZU";
export var gridSpan68 = "Testimonials-module--grid-span-6-8--tCsVE";
export var gridSpan69 = "Testimonials-module--grid-span-6-9--uxFrw";
export var gridSpan710 = "Testimonials-module--grid-span-7-10--m+000";
export var gridSpan711 = "Testimonials-module--grid-span-7-11--5VtgD";
export var gridSpan72 = "Testimonials-module--grid-span-7-2--2r2XH";
export var gridSpan73 = "Testimonials-module--grid-span-7-3--RWaCh";
export var gridSpan74 = "Testimonials-module--grid-span-7-4--YrQqb";
export var gridSpan75 = "Testimonials-module--grid-span-7-5--q9fIm";
export var gridSpan76 = "Testimonials-module--grid-span-7-6--YFwFJ";
export var gridSpan77 = "Testimonials-module--grid-span-7-7--1lisy";
export var gridSpan78 = "Testimonials-module--grid-span-7-8--LI2o0";
export var gridSpan79 = "Testimonials-module--grid-span-7-9--RPUvQ";
export var gridSpan810 = "Testimonials-module--grid-span-8-10--gjfWP";
export var gridSpan811 = "Testimonials-module--grid-span-8-11--Rzv9C";
export var gridSpan82 = "Testimonials-module--grid-span-8-2--FAj5+";
export var gridSpan83 = "Testimonials-module--grid-span-8-3--Xy+TC";
export var gridSpan84 = "Testimonials-module--grid-span-8-4--xp-CD";
export var gridSpan85 = "Testimonials-module--grid-span-8-5--gghue";
export var gridSpan86 = "Testimonials-module--grid-span-8-6--c1KBd";
export var gridSpan87 = "Testimonials-module--grid-span-8-7--foc5v";
export var gridSpan88 = "Testimonials-module--grid-span-8-8--dJaXe";
export var gridSpan89 = "Testimonials-module--grid-span-8-9--vCM07";
export var gridSpan910 = "Testimonials-module--grid-span-9-10--iU7Vs";
export var gridSpan911 = "Testimonials-module--grid-span-9-11--TFCJp";
export var gridSpan92 = "Testimonials-module--grid-span-9-2--F38-Z";
export var gridSpan93 = "Testimonials-module--grid-span-9-3--KvRNu";
export var gridSpan94 = "Testimonials-module--grid-span-9-4--g3EU6";
export var gridSpan95 = "Testimonials-module--grid-span-9-5--2akmY";
export var gridSpan96 = "Testimonials-module--grid-span-9-6--e6DGt";
export var gridSpan97 = "Testimonials-module--grid-span-9-7--JjJnW";
export var gridSpan98 = "Testimonials-module--grid-span-9-8--HqQmg";
export var gridSpan99 = "Testimonials-module--grid-span-9-9--9zzvj";
export var textBreak = "Testimonials-module--text-break--4DBe1";