import {
  BusinessIdea,
  CallToAction,
  Community,
  FAQ,
  Ebook,
  Hero,
  Platform,
  Partners,
  Pricing,
  Testimonials,
  WhyLaunchpad,
  PartnerWithUs,
} from "components/launchpad";
import SEO from "components/SEO";
import { PLATFORM_DATA } from "data/pages/launchpad";
import pagesMeta from "data/pagesMeta";
import { graphql, PageProps } from "gatsby";
import React from "react";

const Launchpad: React.FC<PageProps> = ({ data }) => {
  const {
    allJavascriptFrontmatter: {
      edges: [
        {
          node: { frontmatter: pageData },
        },
      ],
    },
    rewards: {
      edges: [
        {
          node: { data: rewards },
        },
      ],
    },
  } = data as any;

  const featuredRewards = rewards.filter(
    (reward: any) => reward.attributes.isFeatured
  );

  return (
    <>
      <SEO {...pagesMeta.launchpad} />
      <Hero />
      <BusinessIdea />
      <Pricing />
      <Platform platforms={PLATFORM_DATA} />
      <WhyLaunchpad />
      <Partners partners={featuredRewards} />
      <Community />
      <Ebook />
      <Testimonials />
      <PartnerWithUs />
      <FAQ content={pageData.faqContent} />
    </>
  );
};

export const query = graphql`
  query LaunchpadPageQuery {
    allJavascriptFrontmatter(
      filter: { frontmatter: { name: { eq: "launchpad" } } }
    ) {
      edges {
        node {
          frontmatter {
            faqContent {
              answer
              question
            }
          }
        }
      }
    }

    rewards: allStrapiLaunchpadReward {
      edges {
        node {
          data {
            id
            attributes {
              companyName
              rewardValue
              isFeatured
              excerpt
              companyLogo {
                data {
                  attributes {
                    ext
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Launchpad;
