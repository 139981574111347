import React from "react";
import { Accordion, AccordionItem } from "brass-ui-kit";
import { uniqueId } from "lodash";
import * as styles from "./FAQ.module.scss";
import { Link } from "gatsby";
import { launchpadHelpPageUrl } from "data/pageUrl";

interface FAQProps {
  content: { question: string; answer: string }[];
}

const FAQ: React.FC<FAQProps> = ({ content }) => {
  return (
    <section className={styles.FAQ}>
      <div className={styles.FAQ_container}>
        <div className={styles.FAQ_details}>
          <h2 className={styles.FAQ_details_title}>
            Some of the things you may want to know
          </h2>
          <p className={styles.FAQ_details_text}>
            We answered questions so you don't have to ask them.
          </p>
        </div>
        <div className={styles.FAQ_content}>
          <Accordion className={styles.FAQ_content_accordion}>
            {content.map(({ question, answer }) => {
              return (
                <AccordionItem
                  body={answer}
                  header={question}
                  className={styles.FAQ_content_accordion_item}
                  activeClassName={styles.FAQ_content_accordion_item__active}
                  key={uniqueId()}
                />
              );
            })}
          </Accordion>
          <Link to={launchpadHelpPageUrl} className={styles.FAQ_content_link}>
            View the rest of the answered questions
          </Link>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
