import { requestEbookApiRoute } from "data/apiRoute";
import BrassService from "services/brassService";
import LaunchpadService from "services/launchpadService";
import { RequestEbookDataType, WithData } from "types";
import { CompanyType } from "types";

// Request ebook
export const postRequestEbook = async (data: RequestEbookDataType) => {
  const result = await BrassService.post(requestEbookApiRoute, data);
  return result;
};

export const getLaunchpadCompanyTypes = async () => {
  return LaunchpadService.get<WithData<CompanyType[]>>("/company-types", {
    params: {
      limit: 1000,
    },
  });
};
